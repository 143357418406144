import React from "react";
import {Grid} from "@material-ui/core";
import useStyles from "./styles";

import Logo from './../../assets/images/logo_white.png'
import face from './../../assets/images/facebook.svg'
import insta from './../../assets/images/insta.svg'

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.containerFooter}>
      <div >
        <Grid container spacing={6}>
          <Grid item xs>
            <div className={classes.containerEnd}>
              <h3>
                <img
                  src={Logo}
                  alt="Conexo Logo"
                  style={{ width:'50px' }}
                />
                <br /> <br />
                R. LUÍS JOSÉ DE MATOS, 437 | NOVA RIBEIRÂNIA <br />
                RIBEIRÃO PRETO - SP | CEP 14096-680 <br />
                <a href="mailto:contato@conexoadvantages.com.br">
                  CONTATO@CONEXOADVANTAGES.COM.BR
                </a>
                <br />
                CENTRAL DE ATENDIMENTO{" "}
                <a href="tel:+551639659020">(16) 3965-9020</a>
              </h3>{" "}
              <br />
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.containerRedes}>
              <h1>:: Siga a Conexo em ::</h1>
              <h3
                style={{
                  fontSize: 17 + "px",
                  color: "#fff",
                  fontWeight: "normal",
                }}
              >
                <a
                  href="https://www.instagram.com/conexoadvantages/"
                  target="_blank"
                >
                  <img
                    src={insta}
                    alt="Instagram"
                    style={{ width: "20px", paddingLeft: "10px" }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/conexoadvantages/"
                  target="_blank"
                >
                  <img
                    src={face}
                    alt="Facebook"
                    style={{ width: "20px", paddingLeft: "10px", borderRadius: '2px' }}
                  />
                </a>
                /CONEXOADVANTAGES
              </h3>
              <h2
                style={{
                  textAlign: "center",
                  fontSize: 11 + "px",
                  fontWeight: "normal",
                  lineHeight: 22 + "px",
                }}
              >
                <br />
                <a
                  href="/"
                  target="_blank"
                  style={{ color: "#fff", fontSize: "14px" }}
                >
                  TERMOS DE USO{" "}
                </a>{"    "}
                | 
                <a
                  href="/"
                  target="_blank"
                  style={{ color: "#fff", fontSize: "14px" }}
                >
                  {"     "}
                  POLÍTICA DE PRIVACIDADE.
                </a>
              </h2>
              <br />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.rodape}>
        <h1>
          TODOS DIREITOS RESERVADOS | CONEXO BUSINESS ADVANTAGES <br />{" "}
        </h1>
      </div>
    </div>
  );
}

export default Footer;
