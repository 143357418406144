import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Fab } from "@material-ui/core";

import useStyles from "./styles";

import segurovida from "./../../assets/images/segurovida.png";
import seguroauto from "./../../assets/images/seguro-auto.png";
import seguroresidencial from "./../../assets/images/seguro-residencial.jpg";
import assistenciajuri from "./../../assets/images/assistencia-juridica.jpg";
import consorcio from "./../../assets/images/consorcio.jpg";
import financiamento from "./../../assets/images/financiamento.jpg";
import Logobtn from "./../../assets/images/logobtn.png";

function Card() {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.cardContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <div
              className={classes.planos}
              style={{
                backgroundImage: `url(${segurovida})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Link to="/seguros" >
                <button>
                  <img src={Logobtn} /> Seguro de Vida
                </button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              className={classes.planos}
              style={{
                backgroundImage: `url(${seguroauto})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Link to="/seguros" >
                <button>
                  <img src={Logobtn} /> Seguro Automóveis
                </button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              className={classes.planos}
              style={{
                backgroundImage: `url(${seguroresidencial})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Link to="/seguros" >
                <button>
                  <img src={Logobtn} /> Seguro Residencial
                </button>
              </Link>
            </div>
          </Grid>
        </Grid>
        <br />
        <br />
        <div className={classes.secondCard}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <div
              className={classes.planos}
              style={{
                backgroundImage: `url(${assistenciajuri})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Link to="/assistencia-juridica" >
                <button>
                  <img src={Logobtn} /> Assistêcia Jurídica
                </button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              className={classes.planos}
              style={{
                backgroundImage: `url(${consorcio})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Link to="/consorcio" >
                <button>
                  <img src={Logobtn} /> Consórcio
                </button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              className={classes.planos}
              style={{
                backgroundImage: `url(${financiamento})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Link to="/financiamento" >
                <button>
                  <img src={Logobtn} /> Financiamento
                </button>
              </Link>
            </div>
          </Grid>
        </Grid>
        </div>
      </div>
    </div>
  );
}

export default Card;
