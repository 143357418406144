import React, { useState, State, useEffect } from "react";
import emailjs from "emailjs-com";
import useStyles from "./styles";
import InputMask from "react-input-mask";
import { TextField, Grid, InputAdornment, MenuItem } from "@material-ui/core";
import {
  AccountCircle,
  PhoneAndroid,
  Mail,
  Business,
  Message,
  PhoneInTalk,
} from "@material-ui/icons";
import swal from "sweetalert";

function sendEmail(e) {
  e.preventDefault();
  emailjs
    .sendForm(
      "service_dje276b",
      "template_4f00lfn",
      e.target,
      "user_cnyGC7bmrb5Weh5o72ZPr"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log("error.text");
      }
    );
  e.target.reset();
  swal({
    title: `Cadastro enviado com sucesso!`,
    text: "Em breve nossos consultores entrarão em contato!",
    timer: 3500,
    icon: "success",
    timerProgressBar: true,
  });
}

function Formulario() {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={classes.contact}>
        <div className={classes.formStyle}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={12}>
              <form onSubmit={sendEmail}>
                <h1>Solicite mais informações </h1>
                <br/>
                <Grid item>
                  <TextField
                    label="Digite seu nome"
                    name="name"
                    placeholder="Seu nome"
                    required="true"
                    className={classes.formclass}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle style={{ color: "#33819e" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <br />

                <Grid item>
                  <InputMask mask="(99)9 9999 9999">
                    {() => (
                      <TextField
                        label="Celular"
                        name="phone"
                        required="true"
                        placeholder="Celular"
                        className={classes.formclass}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneAndroid style={{ color: "#33819e" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>
                <br />
                <Grid item>
                  <InputMask mask="(99) 9999-9999">
                    {() => (
                      <TextField
                        label="Fixo"
                        name="fixo"
                        placeholder="Telefone Fixo"
                        className={classes.formclass}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneInTalk style={{ color: "#33819e" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>

                <br />
                <Grid item>
                  <TextField
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Insira seu Email"
                    required="true"
                    className={classes.formclass}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Mail style={{ color: "#33819e" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <br />
                <Grid item>
                  <TextField
                    label="Mensagem"
                    type="mensagem"
                    name="mensagem"
                    placeholder="Insira sua mensagem"
                    required="true"
                    className={classes.formclass}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Message style={{ color: "#33819e" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <br />

                <div className={classes.buttonStyle}>
                  <button type="submit">Enviar solicitação</button>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>

        <br />
      </div>
    </div>
  );
}

export default Formulario;
