import React, { useState, useEffect } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import logo from "./../../assets/images/logo_white.png";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import useStyles from "./styles";

import ListIcon from '@material-ui/icons/List';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

function Navbar() {
  const classes = useStyles();
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <nav className={navbar ? classes.NavbarBG : classes.Navbar}>
        <div className={classes.NavbarContainer}>
        

          <Link to="/" className={classes.navbarLogo}>
            <img src={logo} />
          </Link>

          <div className={classes.menuIcon} onClick={handleClick}></div>
          <div className={classes.navMenu}>
            <Link
              to="home"
              className={classes.navLinks}
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Início
            </Link>

            <Link
              to="sobre"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={classes.navLinks}
            >
              Sobre
            </Link>

            <Link
              to="financiamento"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={classes.navLinks}
            >
              Financiamentos
            </Link>
            <Link
              to="seguros"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={classes.navLinks}
            >
              Seguros
            </Link>

            <Link
              to="contato"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={classes.navLinks}
            >
              Contato
            </Link>
          </div>
          {/* <div className={classes.linksPainel}>
            <a href="https://painel.condoall.com.br/" target="_blank">
              Painel
            </a>{" "}
            <a
              style={{
                border: "solid 2px",
                borderRadius: "5px",
                marginLeft: "0.5em",
              }}
              href="https://portaria.condoall.com.br/"
              target="_blank"
            >
              Webmail
            </a>
          </div> */}
        </div>
      </nav>

      <div className={classes.menuNavigateMobile}>
          <PopupState variant="popover" popupId="demo-popup-menu" >
            {(popupState) => (
              <React.Fragment >
                <button
                  className={classes.bntNavigate}
                  {...bindTrigger(popupState)}
                >
                  <ListIcon/>
                </button>
                <Menu {...bindMenu(popupState)} >
                  <MenuItem onClick={popupState.close}>
                    <Link to="/home" className={classes.Navigate} ><h1><HomeIcon/>   Home</h1></Link>
                  </MenuItem>
                  <MenuItem onClick={popupState.close}>
                    <Link to="/sobre" className={classes.Navigate}><h1> <PeopleIcon/>   Sobre</h1></Link>
                  </MenuItem>
                  <MenuItem onClick={popupState.close}>
                    <Link to="/seguros" className={classes.Navigate}><h1><EmojiTransportationIcon/>   Seguros</h1></Link>
                  </MenuItem>
                  <MenuItem onClick={popupState.close}>
                    <Link to="/financiamento" className={classes.Navigate}><h1><AttachMoneyIcon/>   Financiamentos</h1></Link>
                  </MenuItem>
                  <MenuItem onClick={popupState.close}>
                    {" "}
                    <Link to="/contato" className={classes.Navigate}><h1 ><ContactPhoneIcon />   Contato</h1></Link>
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
          <div className={classes.logoMobile}>
            <img src={logo} />
          </div>
          </div>
        
    </>
  );
}

export default Navbar;
