import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Fab, Modal } from "@material-ui/core";
import {Bounce } from 'react-reveal';
import useStyles from "./styles";

import Formulario from "./formulario";

import segurovida from "./../../assets/images/segurovida.png";
import auto from "./../../assets/images/seguro-auto.png";
import resid from "./../../assets/images/seguro-residencial.jpg";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Seguros() {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className={classes.modal}>
   
      <Formulario />
     
    </div>
  );

  return (
    <div>
      <br />
      <div className={classes.seguros}>
        <h1> Sempre tem uma opção que te atenda!</h1>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <div
              className={classes.planos}
              style={{
                backgroundImage: `url(${segurovida})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>TEMOS PLANOS DE SEGURO DE VIDA PARA VOCÊ E A SUA FAMÍLIA.</h3>

            <h4>
              O Seguro de Vida é uma opção para garantir que entes queridos e
              dependentes continuem a levar um estilo de vida de qualidade caso
              o segurado venha a faltar.
              <br />
              <br />É uma garantia oferecida pelas seguradoras em que, mediante
              pagamentos mensais (que chamamos de “prêmios"), uma pessoa pode
              contratar uma indenização financeira que será paga aos
              beneficiários em caso de sua morte, ou a ele mesmo em diversas
              situações previstas em contrato — que vão de doenças graves até o
              reembolso de despesas hospitalares.
            </h4>

            <button type="button" onClick={handleOpen}>
              Contrate
            </button>
           
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <Bounce left>
              {body}
              </Bounce>
            </Modal>
        
          </Grid>
          <br />
        </Grid>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <h3>
              VOCÊ TEM TUDO O QUE ESPERA DE UM SEGURO DE CARRO E, AINDA, CONTA
              COM OUTROS BENEFÍCIOS DISPONÍVEIS 24H.
            </h3>

            <h4>
              Seguro de veículo é seguro para carros, caminhões, motocicletas e
              outros veículos rodoviários.
              <br />
              <br />
              Seu principal uso é fornecer proteção financeira contra danos
              físicos ou lesões corporais resultantes de colisões no trânsito e
              contra a responsabilidade que também pode resultar de incidentes
              em um veículo.
            </h4>
            <button type="button" onClick={handleOpen}>
              Contrate
            </button>
           
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <Bounce left>
              {body}
              </Bounce>
            </Modal>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              className={classes.planos}
              style={{
                backgroundImage: `url(${auto})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Grid>

          <br />
        </Grid>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <div
              className={classes.planos}
              style={{
                backgroundImage: `url(${resid})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>SEU LAR PROTEGIDO 24H.</h3>

            <h4>
              Essa modalidade de seguro oferece proteção aos imóveis destinados
              à moradia, sejam eles permanentes ou de temporada.
              <br />
              <br />
              Em alguns casos, esta proteção pode se referir tanto a estrutura
              física do imóvel como ao que está dentro dele.
            </h4>
            <button type="button" onClick={handleOpen}>
              Contrate
            </button>
           
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <Bounce left>
              {body}
              </Bounce>
            </Modal>
          </Grid>
          <br />
        </Grid>
        <br />
      </div>
    </div>
  );
}
export default Seguros;
