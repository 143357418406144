import { makeStyles } from "@material-ui/core/styles";


// import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
  contato:{
    
  },
  map:{
    width: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '5em',
  },
  mapcontainer:{
    width: '400px',
    height: '400px',
    borderRadius: '15px',
    allowfullscreen: 'none',
    loading: 'lazy',
    marginTop: '3em',
    border: '0'
  },
  formclass:{
    width: "50ch" ,
    [theme.breakpoints.only("xs")]: {
      width: "35ch" ,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  Form:{
    color: '#3b3b3b',
   
    marginTop: '2em',
    '& h1':{
        fontSize: '22px',
    },
    "& button": {
      width: "190px",
      height: "35px",
      border: "none",
      color: "#fff",      
      background: "#328aa3",
      borderRadius: "3px",
      transition: "ease-out 0.5s",
      outline: "none",
      fontSize: "15px",
      marginTop: '3em',
      '& img':{
        width: '12px',
      },
      "&:hover": {
        boxShadow: "inset 190px 0 0 0 #000",     
        color: "#fff",
        cursor: "pointer",
      },
    },
  },
}));

export default useStyles;
