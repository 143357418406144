import React, { useState, useEffect } from "react";
import useStyles from "./styles";

import Card from './../../components/Card/index'
import logo from "../../assets/images/logo-conexo.svg"




function Home() {

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
    return (
      <div className={classes.home}>
        <div className={classes.heroHome}>
          <img src={logo} />
        </div>
        <p>
          UMA REDE COMPLETA <br />
          DE VANTAGENS
        </p>
        <h3
          style={{
            fontSize: "24px",
            color: "grey",
            paddingTop: "1.5em",
            textAlign: "center",
            fontWeight: "normal",
          }}
        >
          #SejaConexo
        </h3>
        {/* <div className={classes.cashback}> */}
          
          {/* <h1>:: CONEXO CASHBACK ::</h1>
          <h2>
            Um app que te aproxima dos melhores estabelecimentos
            <br />
            descontos, presentes e cashback direto no seu celular
          </h2>
          <br />
          <img
        
          />
          <br /> */}
          {/* <div className={classes.apps}>
            <a
              href="https://play.google.com/store/apps/details?id=com.conexo_app"
              target="_blank"
            >
              <img
                src={require("")}
              />
            </a>
            <a
              href="https://apps.apple.com/br/app/conexo-advantages/id1509535376"
              target="_blank"
            >
              <img
                style={{
                  marginLeft: "5%",
                }}
                src={require("")}
              />
            </a>
          </div> */}
        {/* </div> */}
        <div className={classes.Cards}>  
        <h1>Saiba mais sobre<br/> nossos serviços </h1>
          <br/>

        <Card />
        <br/>
        <br/>
        </div>
      </div>
    );
}

export default Home;
