import { makeStyles } from "@material-ui/core/styles";

import bg from './../../assets/images/backgroundtriang.png'

import montains from './../../assets/images/montains.svg'

const useStyles = makeStyles((theme) => ({
  seguros:{
    width: '900px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    '& h3':{
      marginTop: '4em',
    },
    '& h1':{
      fontSize: '20px',
      textAlign: 'center',
    },
    "& button": {
      width: "190px",
      height: "35px",
      border: "none",
      color: "#fff",      
      background: "linear-gradient(45deg, #6b9396 30%, #08897b 90%)",
      borderRadius: "3px",
      transition: "ease-out 0.3s",
      outline: "none",
      fontSize: "15px",
      '& img':{
        width: '12px',
      },
      "&:hover": {
        boxShadow: "inset 190px 0 0 0 #000",     
        color: "#fff",
        cursor: "pointer",
      },
    },
    [theme.breakpoints.down("md")]: {
      width: '90%'
    }
  } , 
  planos: {
    marginTop: '4em',
    width: "350px",
    height: '200px',
    padding: "50px 15px",
    borderRadius: "8px",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#FFF",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      width: '90%'
    },
  
    '&:hover':{
      boxShadow: '0px 1px 5px 0px #08897b',
      transition: '0.3s'
    },
    "& p": {
      textAlign: "left",
      marginTop: "3em",
      lineHeight: "1.5em",
    },
  
   
    
  },
  modal: {   
    width: '400px',
    height: '500px',
    background: "#fff",
    backgroundImage: `url(${montains})`,
    backgroundSize: "cover",
    backgroundRepeat: 'no-repeat',
    borderRadius: '5px',
    marginLeft: "auto",
    marginRight: 'auto',
    marginTop: '5em',
    [theme.breakpoints.down("md")]: {
      width: '90%'
    },
  },
  formStyle:{
    textAlign: 'center',
  },
  formclass:{
    width: "40ch" , 
    [theme.breakpoints.down("md")]: {
      width: "30ch" , 
    },
  },
  contact:{
    color: '#328aa3',
    '& h1':{
      fontSize: '18px'
    },
    "& button": {
      width: "190px",
      height: "35px",
      border: "none",
      color: "#fff",      
      background: "#328aa3",
      borderRadius: "3px",
      transition: "ease-out 0.5s",
      outline: "none",
      fontSize: "15px",
      marginTop: '3em',
      '& img':{
        width: '12px',
      },
      "&:hover": {
        boxShadow: "inset 190px 0 0 0 #000",     
        color: "#fff",
        cursor: "pointer",
      },
    },
  },
}));

export default useStyles;
