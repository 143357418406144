import { makeStyles } from "@material-ui/core/styles";

import fundobg from "./../../assets/images/backgroundtriang.png";
import background from "./../../assets/images/Fundosobre.svg";

// import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
  home: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundImage: `url(${fundobg})`,
    backgroundSize: '100%',
    fontFamily: "Bungee Inline",
    "& p": {
      fontSize: "37px",
      color: "#328aa3",
      textAlign: "center",
      lineHeight: "1.5",
      maxWidth: "100%",
      marginTop: "-0.5em",

      lineHeight: 1,
    },
    [theme.breakpoints.only("xs")]: {
      "& p": {
        fontSize: "24px",
        lineHeight: "1em",
      },
    },
  },
  heroHome: {
    
    "& img": {
      width: "50%",
      alignItems: "center",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cashback: {
    padding: "4rem",
    height: '90vh',
    fontFamily: "Bungee Inline",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    margin: "0 auto",
    backgroundImage: `url(${background})`,
    color: '#fff',
    "& h1": {
      textAlign: "center",
      fontFamily: "Bungee Inline",
      fontSize: "40px",
      fontWeight: "normal",
      lineHeight: 3,
 
    },
    "& h2": {
      textAlign: "center",
      fontFamily: "Advent Pro",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: 1.5,
   
    },
    "& img": {
      width: "30%",
      flexFlow: "column",
      display: "flex",
      margin: "0 auto",
      lineHeight: 3,
    },
    [theme.breakpoints.only("xs")]: {
      "& h1": {
        fontSize: "20px",
        lineHeight: "1em",
      },
      "& h2": {
        fontSize: "20px",
        lineHeight: "1em",
        paddingTop: "2em",
      },
    },
  },
  apps: {
    "& img": {
      paddingTop: "1.5em",
      width: "47%",
      flexFlow: "column",
      display: "inline-block",
      marginRight: 0,
      alignItems: "center",
      "&:hover": {
        width: "46%",
        transition: "0.2s",
      },
    },
  },
  Cards:{
    '& h1':{
      color: '#45696b',
      fontFamily: 'Roboto',
      marginTop: '3em',
      textAlign: 'center',
      lineHeight: '1em',
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: '12px'
    }
  }
}));

export default useStyles;
