import { makeStyles } from "@material-ui/core/styles";

import financiamento from './../../assets/images/financiamento-1.png'
import bgtriang from './../../assets/images/backgroundtriang.png'

const useStyles = makeStyles((theme) => ({
    financiamento: {
        width: '100%',
        height: '80vh',
        maxWidth: '100%',
        maxHeight: '100%',
        backgroundImage: `url(${financiamento})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',  
        marginTop: '-2em',
      
        '& h1': {
          fontSize: '42px',
          maxHeight: '95%',
          maxWidth: '95%',
          textAlign: 'center',
          paddingTop: '5em',
          fontFamily: 'Bungee Inline',
          color: '#fff',
          lineHeight: 1,
          fontWeight: 'normal',
          textShadow: '0px 0px 10px #000',
          opacity: 1
        },
        [theme.breakpoints.only('xs')]:{  
          height: '220px',
          '& h1': {    
            fontSize: '23px',  
            textAlign: 'center'    
          },
        }  
      },
      infosFinanciamento: {
        backgroundImage: `url(${bgtriang})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      },
      
      infosBox: {
        width: '700px',
        height: '100%',
        maxWidth: '100%',
      '& h3': {
        color: '#328aa3',
        maxWidth: '98%',
        maxHeight: '100%',
        fontSize: '39px',
        textAlign: 'center',
        fontWeight: 'normal',
        paddingTop: '2em'
      },
      '& h4': {
        color: '#328aa3',
        maxWidth: '98%',
        maxHeight: '100%',
        fontSize: '28px',
        textAlign: 'center',
        fontWeight: 'normal',
        paddingTop: '2em',
        lineWeight: 'normal',
        fontFamily: 'Advent Pro'
      },
        
      [theme.breakpoints.only('xs')]:{    
        width: '90%',
        '& h3': {    
          fontSize: '23px',
      
        },
        '& h4': {    
          fontSize: '20px',
        },
      }
      }  ,
      contact:{
        color: '#328aa3',
        "& button": {
          width: "190px",
          height: "35px",
          border: "none",
          color: "#fff",      
          background: "#328aa3",
          borderRadius: "3px",
          transition: "ease-out 0.5s",
          outline: "none",
          fontSize: "15px",
          marginTop: '3em',
          '& img':{
            width: '12px',
          },
          "&:hover": {
            boxShadow: "inset 190px 0 0 0 #000",     
            color: "#fff",
            cursor: "pointer",
          },
        },
      },
      formStyle:{
        textAlign: 'center',
      },
      formclass:{
        width: "50ch" ,
        [theme.breakpoints.down("md")]: {
          width: "85%" ,  
        }  
      }
}));

export default useStyles;
