import { makeStyles } from "@material-ui/core/styles";

import backtopo from "./../../assets/images/backgroundfooter.png";

// import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
  containerFooter: {
    backgroundImage: `url(${backtopo})`,
    backgroundColor: "#000",
    maxHeight: "100%",
    maxWidth: "100%",
    height: "100%",
    overflowX: "hidden",
    paddingTop: "3em",
    color: "#fff",
  },
  containerRedes: {
    alignItems: "center",
    margin: "0 auto",
    textAlign: "center",
    "& h1": {
      fontSize: "20px",

      fontFamily: "Bungee Inline",
      textAlign: "center",
      fontWeight: "normal",
    },
    [theme.breakpoints.only("xs")]: {
      "& h1": {
        fontSize: "18px",
        marginTop: "-3.5em",
      },
    },
  },
  containerEnd: {
    alignItems: "center",
    margin: "0 auto",
    textAlign: "center",
    "& h3": {
      fontSize: "17px",
      fontWeight: "normal",
      lineHeight: "22px",
  
    },
    "& a": {
      fontSize: "17px",
      color: "#fff",
      fontWeight: "normal",
      lineHeight: "22px",
    },
    [theme.breakpoints.only("xs")]: {
      "& h3": {
        fontSize: "14px",
      },
      "& a": {
        fontSize: "14px",
      },
    },
  },
  rodape: {
    "& h1": {
      fontSize: "18px",
      fontFamily: "Advent Pro",
      textAlign: "center",
      fontWeight: "normal",
    },
    [theme.breakpoints.only("xs")]: {
      "& h1": {
        fontSize: "14px",
        marginTop: "-3em",
      },
    },
  },
}));

export default useStyles;
