import React, { useState, State, useEffect } from "react";
import emailjs from "emailjs-com";
import useStyles from "./styles";
import InputMask from "react-input-mask";
import { TextField, Grid, InputAdornment, MenuItem } from "@material-ui/core";
import {
  AccountCircle,
  PhoneAndroid,
  Mail,
  Business,
  Message,
  PhoneInTalk,
} from "@material-ui/icons";
import swal from "sweetalert";

function sendEmail(e) {


    e.preventDefault();
    emailjs
      .sendForm(
        "service_mpan5wp",
        "template_pay",
        e.target,
        "user_fXBpi2jqOrNp0rmoUkXRM"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log("error.text");
        }
      );
    e.target.reset();
    swal({
      title: `Cadastro enviado com sucesso!`,
      text: "Em breve nossos consultores entrarão em contato!",
      timer: 3500,
      icon: "success",
      timerProgressBar: true,
    });
  }

  

function Contato() {

    
  const classes = useStyles();

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.contato}>
      <Grid container spacing={12}>
        <Grid item xs={12} sm={6}>
          <div className={classes.map}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.8664557241837!2d-47.77515508544159!3d-21.19746338444016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b9bf52b03aaaa9%3A0x234926105a66438d!2sConexo%20Advantages!5e0!3m2!1spt-BR!2sbr!4v1623692814242!5m2!1spt-BR!2sbr"
              className={classes.mapcontainer}
            ></iframe>
            Conexo Business Advantages <br />
            R. Luís José de Matos, 437 - Ribeirânia, Ribeirão Preto - SP,
            14096-680
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
            <div className={classes.Form}>
              <form onSubmit={sendEmail}>
                <h1>Solicite nosso contato: 
                   
                </h1>

                <Grid item>
                  <TextField
                    label="Digite seu nome"
                    name="name"
                    placeholder="Seu nome"
                    required="true"
                    className={classes.formclass}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle style={{ color: "#33819e" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <br />

                <Grid item>
                  <InputMask mask="(99)9 9999 9999">
                    {() => (
                      <TextField
                        label="Celular"
                        name="phone"
                        required="true"
                        placeholder="Celular"
                        className={classes.formclass}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneAndroid style={{ color: "#33819e" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>
                <br />
                <Grid item>
                  <InputMask mask="(99) 9999-9999">
                    {() => (
                      <TextField
                        label="Fixo"
                        name="fixo"
                        placeholder="Telefone Fixo"
                        className={classes.formclass}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneInTalk style={{ color: "#33819e" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>

                <br />
                <Grid item>
                  <TextField
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Insira seu Email"
                    required="true"
                    className={classes.formclass}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Mail style={{ color: "#33819e" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <br />
                <Grid item>
                  <TextField
                    label="Mensagem"
                    type="mensagem"
                    name="mensagem"
                    placeholder="Insira sua mensagem"
                    required="true"
                    className={classes.formclass}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Message style={{ color: "#33819e" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <br />

                <div className={classes.buttonStyle}>
                  <button type="submit">Enviar solicitação</button>
                </div>
              </form>
              </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Contato;
