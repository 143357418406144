import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Navbar: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundSize: "100%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.1rem",
    position: "sticky",
    background: "#33819e",
    textDecoration: "none",
    top: 0,
    zIndex: 999,
    [theme.breakpoints.down("md")]: {
      overflowX: "hidden",
    },
  },
  NavbarBG: {
    maxWidth: "100%",
    maxHeight: "100%",
    fontFamily: "Roboto",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    background: "#868686",
    top: -0,
    zIndex: 999,
    fontWeight: "bold",
    transition: "0.3s",

    [theme.breakpoints.down("md")]: {
      top: 0,
      fontSize: "1.1rem",
    },
  },
  linksPainel: {
    "& a": {
      textDecoration: "none",
      "&:hover": {
        transition: "0.3s ease-out",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },

  NavbarContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  navMenu: {
    display: "grid",
    gridTemplateColumns: "repeat(5, auto)",
    gridArea: "16px",
    listStyle: "none",
    textAlign: "center",
    width: "60vw",
    justifyContent: "end",
    marginRight: "2rem",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  navItem: {
    height: "80px",
    "&:a": {
      textAlign: "center",
      color: "red",
    },
  },

  navLinks: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    padding: "0rem 1.5rem",
    height: "100%",
    cursor: "pointer",
    textAlign: "center",
    color: "#fff",
    "& a": {
      textAlign: "center",
    },

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      maxWidth: "100%",
    },
  },
  navLinksMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
      margin: "2rem auto",
      borderRadius: "41px",
      width: "50%",
      textDecoration: "none",
      backgroundColor: "transparent",
      padding: "14px 20px",
      border: "2px solid #fff",
      transition: "all 0.3s ease-out",
    },
  },
  menuIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "absolute",
      top: 0,
      right: 5,
      transform: "translate(-100%, 60%)",
      cursor: "pointer",
    },
  },
  navbarLogo: {
    "& img": {
      marginLeft: "5em",
      width: "40px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  navMenuActive: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "90vh",
    position: "absolute",
    top: "75px",
    opacity: 1,
    transition: "all 0.5s ease",
    background: "#242222",
    left: 0,
    listStyle: "none",
    zIndex: "1",
  },
  styleInformation: {
    background: "red",
  },

  Navigate: {
    marginLeft: "2em",
    float: "left",
    "& h1": {
      textDecoration: "none",
      fontSize: "17px",
      color: "#000",
    },
  },
  bntNavigate: {
    marginLeft: "2em",
    marginTop: '0.9em',
    backgroundColor: "#fff",
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    border: "none",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      transition: "0.3s",
    },
  },
  menuNavigateMobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "1.1rem",
      position: "sticky",
      background: "#33819e",
      textDecoration: "none",
      height: "65px",
      top: 0,
      zIndex: 999,
      "& h1": {
        textDecoration: "none",
        fontSize: "17px",
        color: "#000",
      },
    },
  },
  logoMobile: {
    display: "none",

    [theme.breakpoints.down("md")]: {
      display: "block",
      paddingTop: "0.7em",
      marginLeft: "auto",
      marginRight: "1.5em",

      "& img": {
        width: "40px",
        height: "40px",
      },
    },
  },
}));
export default useStyles;
