import { makeStyles } from "@material-ui/core/styles";

import sobre from "../../assets/images/Fundosobre.svg";
import triangulo from "../../assets/images/backgroundtriang.png";
// import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
  containerSobre: {
    background: "black",
    fontFamily: "Bungee Inline cursive",
    maxWidth: "100%",
    maxHeight: "100%",
  
    backgroundImage: `url(${sobre})`,

    "& h1": {
      fontSize: "20px",
      textAlign: "center",
      fontFamily: "Advent Pro",
      marginTop: "-2em",
    },
    "& h2": {
      fontSize: "36px",
      maxHeight: "100%",
      maxWidth: "100%",
      textAlign: "center",
      paddingTop: "1em",
      fontFamily: "Bungee Inline",
      color: "#fff",
      lineHeight: 1,
      fontWeight: "normal",
      textShadow: "0px 0px 10px #328aa3",
    },
    [theme.breakpoints.only("xs")]: {
      "& h2": {
        fontSize: "24px",
      },
    },
  },


  sobre: {
    maxWidth: "100%",
 
    fontFamily: "Bungee Inline",
    alignItems: "center",
    textAlign: "center",

    "& img": {
      width: "350px",
      height: "auto",
      float: "right",
    },
    "& h1": {
      maxWidht: "95%",
      maxHeight: "95%",
      display: "inline-block",
      fontSize: "26px",
      color: "#fff",
      fontFamily: "Advent Pro",
    },
    "& p": {
      display: "inline-block",
      fontSize: "22px",
      color: "#fff",
      textAlign: "left",
      fontFamily: "Advent Pro",
      float: "left",
      paddingLeft: "1.5em",
      width: "450px",
      paddingTop: "-2em",
    },
    [theme.breakpoints.only("xs")]: {
      "& p": {
        width: "350px",
      },
    },
  },

  sobreSecond: {
    width: "100%",
    height: "auto",
    backgroundImage: `url(${triangulo})`,
    backgroundSize: "cover",
    backgroundColor: "#fff", 
    fontFamily: "Bungee Inline",
    alignItems: "center",
    textAlign: "center",

    "& img": {
      width: "80%",
      marginLeft: "auto",
      float: 'left'
    },
    "& h1": {
      fontSize: "30px",
      color: "#328aa3",
      textAlign: "center",
      fontFamily: "Bungee Inline",
      fontWeight: "normal",
    },
    "& p": {
      display: "inline-block",
      fontSize: "22px",
      color: "#000",
      textAlign: "left",
      fontFamily: "Advent Pro",
      marginLeft: '5em',
      width: "450px",
    },

    [theme.breakpoints.only("xs")]: {
      alignItems: "center",
      textAlign: "center",
      margin: "0 auto",
      float: "none",
      "& h1": {
        fontSize: "18px",
      },
      "& p": {
        fontSize: "24px",       
        textAlign: "center",
        marginLeft: '0em',
        width: "98%",
      },
      "& img": {
        width: "100%",
      },
    },
  },
}));

export default useStyles;
