import React from "react";

import useStyles from "./styles";
import Formulario from './../financiamento/formulario'
import CardItem from "../../components/Card/index";

function Consorcio() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.financiamento}>
        <h1>Consórcio</h1>
      </div>
      <div className={classes.infosFinanciamento}>
        <div className={classes.infosBox}>
        <h3>INVESTIMENTO QUE TRAZ RETORNO PARA VOCÊ..</h3>
          <h4>
            O consórcio é uma modalidade de investimento que permite investir em
            bens de alto valor, como casa, automóvel e serviços de forma
            planejada..
            <br />
            <br />
            Diferentemente do financiamento, o cliente não recebe o bem assim
            que passa a pagar as mensalidades, mas pode contar com inúmeros
            diferenciais e benefícios que levam em conta o seu planejamento
            financeiro
          </h4>
          <br />
          <br />
          <br />
        </div>
      </div>
      <Formulario/>
      <br />
      <br />
      <CardItem />
      <br />
    </div>
  );
}
export default Consorcio;
