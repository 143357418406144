import React from "react";

import useStyles from "./styles";
import Formulario from './formulario'
import CardItem from "../../components/Card/index";

function Financiamento() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.financiamento}>
        <h1>FINANCIAMENTOS</h1>
      </div>
      <div className={classes.infosFinanciamento}>
        <div className={classes.infosBox}>
          <h3>SOLUÇÃO PARA REALIZAR SONHOS.</h3>

          <h4>
            Um financiamento nada mais é do que uma operação financeira que visa
            o parcelamento de um produto (ou serviço) para um consumidor que não
            seria capaz de adquiri-lo se fosse necessário o pagamento à vista.
            <br />
            <br />
            Assim como em outras operações financeiras, a instituição
            responsável por prover recursos nessa compra cobra um valor para
            ceder o dinheiro. Essa cobrança é feita, como de costume, com a
            inclusão de uma taxa de juros sobre o valor inicial.
          </h4>
          <br />
          <br />
          <br />
        </div>
      </div>
      <Formulario/>
      <br />
      <br />
      <CardItem />
      <br />
    </div>
  );
}
export default Financiamento;
