import React from 'react';
import './styles.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'



import Navbar from './components/Navbar/index'
import Footer from './components/Footer/index'

import Home from './pages/home/index'
import Sobre from './pages/sobre/index'
import Financiamento from './pages/financiamento/index'
import Seguro from './pages/seguros/index'
import Consorcio from './pages/consorcio/index'
import Juridica from './pages/assistjuridica/index'

import Contato from './pages/contato/index'



function Routes() {

  return (
    <>
    
    <Router> 
    <Navbar/>
      <Switch> 
    
      <Route path='/' exact component = {Home}/>
      <Route path='/sobre' exact component = {Sobre}/>
      <Route path='/financiamento' exact component = {Financiamento}/>
      <Route path='/seguros' exact component = {Seguro}/>
      <Route path='/consorcio' exact component = {Consorcio}/>
      <Route path='/assistencia-juridica' exact component = {Juridica}/>


      <Route path='/contato' exact component = {Contato}/>

  
     
      <Route path='*' exact={true} component = {Home}/>
      </Switch>  
      <Footer/>
    </Router>

    </>
  );
}

export default Routes;