import React from "react";

import useStyles from "./styles";
import Formulario from './formulario'
import CardItem from "../../components/Card/index";

function Juridica() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.financiamento}>
        <h1>Assistência Jurídica</h1>
      </div>
      <div className={classes.infosFinanciamento}>
        <div className={classes.infosBox}>
        <h3>
              ENGLOBA O SERVIÇO DE REPRESENTAÇÃO, EM JUÍZO, DA PARTE QUE REQUER
              E TEM DEFERIDA A CITADA ASSISTÊNCIA.
            </h3>
            <h4>
              A assessoria jurídica é o serviço que um advogado ou um escritório
              de advocacia presta para uma organização com o intuito de oferecer
              segurança jurídica para o negócio em suas diferentes áreas.
              <br />
              <br />
            </h4>
          <br />
          <br />
          <br />
        </div>
      </div>
      <Formulario/>
      <br />
      <br />
      <CardItem />
      <br />
    </div>
  );
}
export default Juridica;
