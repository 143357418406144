import { makeStyles } from "@material-ui/core/styles";

import bgbtn from "./../../assets/images/backgroundtriang.png";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    height: "100%",
   
  },
  cardContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "70%",
  },
  planos: {
    width: "250px",
    height: '100px',
    padding: "50px 15px",
    borderRadius: "8px",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#FFF",
    textAlign: "center",
    '&:hover':{
      boxShadow: '0px 1px 5px 0px #08897b',
      transition: '0.3s'
    },
    "& p": {
      textAlign: "left",
      marginTop: "3em",
      lineHeight: "1.5em",
    },
    "& img": {
      marginTop: "-2em",
    },
    "& button": {
      width: "190px",
      height: "35px",
      border: "none",
      color: "#fff",      
      background: "linear-gradient(45deg, #6b9396 30%, #08897b 90%)",
      borderRadius: "3px",
      transition: "ease-out 0.3s",
      outline: "none",
      fontSize: "15px",
      marginTop: '7em',
      '& img':{
        width: '12px',
      },
      "&:hover": {
        boxShadow: "inset 190px 0 0 0 #000",     
        color: "#fff",
        cursor: "pointer",
      },
    },
  },
  secondCard:{
    [theme.breakpoints.down("md")]: {

    marginTop: '-2em',
    }
  }

}));
export default useStyles;
