import React, { useState, State, useEffect } from "react";
import useStyles from './styles'
import Grid from "@material-ui/core/Grid";

import Cards from "../../components/Card/index";

import conectarpessoas from "../../assets/images/conectar-pessoas.png"
import sobre from "../../assets/images/sobre-conexo-advantages.svg"

function Sobre() {

const classes = useStyles();

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  return (
    <div >

      <div className={classes.containerSobre} id="sobre">
        <h1>#SejaConexo</h1>
        <h2>
          UMA REDE COMPLETA <br />
          DE VANTAGENS
        </h2>

        <div className={classes.sobre}>
          <Grid container spacing={12}>
            <Grid item xs={12} sm={6}>
              <img
                src={sobre}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <p>
                <h1>SOBRE NÓS</h1>
                <br />
                A Conexo nasceu em 2013 como uma ideia de ser um aplicativo que
                levasse benefícios ao cliente final.
                <br />
                <br /> O que começou apenas como um app de cashback, que retorna
                crédito ao consumidor que gasta nas lojas parceiras, hoje se
                tornou uma empresa com uma ampla rede de benefícios, englobando
                desde seguro saúde e residencial, a opções de financiamento de
                veículos, consórcios, assistência jurídica, medicina do
                trabalho, entre outras vantagens
              </p>
            </Grid>
          </Grid>
          <br />
          <br />
        </div>

        <div className={classes.sobreSecond}>
          <h1>::Conectar pessoas::</h1>
          <br />
          <Grid container spacing={12}>
            <Grid item xs={12} sm={6}>
              <p>
                Nossa missão é conectar clientes e parceiros, oferecendo
                vantagens a custos acessíveis e fortalecendo o senso de
                comunidade, afinal, juntos podemos mais.
                <br />
                <br />
                A Conexo é isso: uma família.
                <br />
                <br />
                Seja Conexo também e junte-se aos milhares de clientes e
                parceiros que já fazem parte desta história.{" "}
              </p>
            </Grid>

            <Grid item xs={12} sm={6}>
              <img
                src={conectarpessoas}
              />
            </Grid>
          </Grid>
          <div
            style={{
              fontFamily: "Advent Pro",
              color: "black",
              fontSize: "24px",
              paddingTop: "1.5em",
            }}
          >
            #SejaConexo
          </div>
        </div>
      </div>
      <br/>
      <Cards />
      <br/>
    </div>
  );
}

export default Sobre;
